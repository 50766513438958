<template>
  <tenant-settings-provider v-slot="{ save, saving, fetching, settings }">
    <div>
      <page-header title="Переменные" class="mb-2">
        <template #right>
          <v-btn
            @click="() => save(settings)"
            color="success"
            :disabled="saving"
          >{{ saving ? 'Сохранение...' : 'Сохранить' }}</v-btn>
        </template>
      </page-header>

      <div v-if="fetching">
        Загрузка...
      </div>
      <div v-else-if="settings">
        <settings-variables-editor v-model="settings.frontendEnvVariables" />
      </div>
    </div>
  </tenant-settings-provider>
</template>

<script>
import TenantSettingsProvider from '../Tenant/TenantSettingsProvider.vue'
import SettingsVariablesEditor from './SettingsVariablesEditor.vue'
import PageHeader from '@/components/PageHeader.vue'

export default {
  components: {
    PageHeader,
    SettingsVariablesEditor,
    TenantSettingsProvider,
  }
}
</script>
