<template>
  <v-select
    v-bind="attrs"
    v-on="$listeners"
    outlined
    dense
  />
</template>

<script>
const FIELD_TEXT = 'text'
const FIELD_VALUE = 'value'

export default {
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        items: [
          {
            [FIELD_TEXT]: 'Europe/Moscow',
            [FIELD_VALUE]: 'Europe/Moscow'
          },
          {
            [FIELD_TEXT]: 'Asia/Dubai',
            [FIELD_VALUE]: 'Asia/Dubai'
          }
        ]
      }
    }
  }
}
</script>
