<template>
  <div>
    <v-text-field
      name="name"
      label="Название переменной"
      v-model="form.name"
      class="mb-4"
      hide-details
      dense
      outlined
      @input="input"
    />
    <v-textarea
      rows="1"
      auto-grow
      name="value"
      label="Значение"
      v-model="form.value"
      hide-details
      dense
      outlined
      @input="input"
      class="mb-4"
    />
    <v-checkbox label="Is secret" v-model="form.secret" />
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
class Model {
  constructor (options) {
    const {
      name = null,
      value = null,
      secret = false
    } = options || {}
    Object.assign(this, {
      name,
      value,
      secret,
    })
  }
}
export default {
  props: {
    variable: {
      type: Object,
      default: () => new Model()
    }
  },
  data () {
    return {
      form: new Model(this.variable)
    }
  },
  watch: {
    variable: {
      deep: true,
      handler (variable) {
        if (isEqual(variable, this.form)) return
        this.form = new Model(variable)
      }
    }
  },
  methods: {
    input () {
      this.$emit('input', this.form)
    }
  }
}
</script>
