<script>
import isEqual from 'lodash/isEqual'
export default {
  props: ['value'],
  render () {
    return this.$scopedSlots.default({
      choose: this.choose.bind(this),
      value: this.innerValue,
      remove: this.remove.bind(this)
    })
  },
  data () {
    return {
      innerValue: this.value
    }
  },
  watch: {
    value: {
      deep: true,
      handler (value) {
        if (isEqual(value, this.innerValue)) return
        this.innerValue = value
      }
    }
  },
  methods: {
    remove () {
      this.innerValue = null
      this.$emit('input', this.innerValue)
    },
    choose () {
      return this
        .openPhotoModal()
        .$once('choose', (photo) => {
          this.innerValue = photo
          this.$emit('input', this.innerValue)
        })
    }
  },
}
</script>
