<template>
  <v-select
    v-bind="attrs"
    v-on="$listeners"
    outlined
    dense
  />
</template>

<script>
const FIELD_TEXT = 'text'
const FIELD_VALUE = 'value'

export default {
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        multiple: true,
        disabled: true,
        items: [
          {
            [FIELD_TEXT]: 'Ru',
            [FIELD_VALUE]: 'ru'
          },
          {
            [FIELD_TEXT]: 'En',
            [FIELD_VALUE]: 'en'
          }
        ]
      }
    }
  }
}
</script>
