<template>
  <div>
    <v-row class="mb-4">
      <v-col cols="2">
        <v-icon color="success">mdi-eye-outline</v-icon>
        <span class="ml-2">Публичная</span>
      </v-col>
      <v-col>
        <v-icon>mdi-eye-off-outline</v-icon>
        <span class="ml-2">Секретная</span>
      </v-col>
    </v-row>
    <list-editor-light
      v-bind="{
        ...$attrs,
        sortable: false,
        items: $attrs.value,
        dialogOptions: { maxWidth: 500 }
      }"
      v-on="$listeners"
    >
      <template #content="{ item }">
        <div>
          <v-icon :color="item.secret ? '' : 'success'">{{ item.secret ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
          <span class="ml-2 text--disabled">{{ item.name }}:</span>
          {{ item.value }}
        </div>
      </template>
      <template #dialog="{ model, input }">
        <settings-variable :variable="model" @input="input" />
      </template>
    </list-editor-light>
  </div>
</template>

<script>
import SettingsVariable from '@/components/Settings/SettingsVariable.vue'
import ListEditorLight from '@/components/ListEditor/ListEditorLight.vue'

export default {
  components: {
    SettingsVariable,
    ListEditorLight
  },
}
</script>
