<script>
import tenantService from '@/services/tenant'
import { createRequest } from '@/utils/requestsFactory'

export default {
  data () {
    return {
      save: createRequest((payload) => {
        let data = payload
        if (tenantService.toDto) {
          data = tenantService.toDto(data)
        }
        return tenantService.update(data)
      }),
      fetch: createRequest(() => tenantService.getAll())
    }
  },
  created () {
    return this.fetch.run()
  },
  render () {
    const defaultSlot = this.$scopedSlots.default
    if (!defaultSlot) return null
    const {
      run: save,
      error: savingError,
      data: response,
      pending: saving
    } = this.save
    const {
      run: fetch,
      error: fetchingError,
      data: settings,
      pending: fetching
    } = this.fetch
    return defaultSlot({
      fetch,
      fetching,
      fetchingError,
      settings,
      save: save.bind(this),
      saving,
      savingError,
      response,
    })
  }
}
</script>
