var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-eye-outline")]),_c('span',{staticClass:"ml-2"},[_vm._v("Публичная")])],1),_c('v-col',[_c('v-icon',[_vm._v("mdi-eye-off-outline")]),_c('span',{staticClass:"ml-2"},[_vm._v("Секретная")])],1)],1),_c('list-editor-light',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"content",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-icon',{attrs:{"color":item.secret ? '' : 'success'}},[_vm._v(_vm._s(item.secret ? 'mdi-eye-off-outline' : 'mdi-eye-outline'))]),_c('span',{staticClass:"ml-2 text--disabled"},[_vm._v(_vm._s(item.name)+":")]),_vm._v(" "+_vm._s(item.value)+" ")],1)]}},{key:"dialog",fn:function(ref){
var model = ref.model;
var input = ref.input;
return [_c('settings-variable',{attrs:{"variable":model},on:{"input":input}})]}}])},'list-editor-light',Object.assign({}, _vm.$attrs,
      {sortable: false,
      items: _vm.$attrs.value,
      dialogOptions: { maxWidth: 500 }}),false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }