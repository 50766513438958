import isEqual from 'lodash/isEqual'
const MODEL_PROP = 'value'
const MODEL_EVENT = 'input'
const INNER_VALUE = 'form'

export default function createFormValueMixin ({
  modelName = MODEL_PROP,
  modelEvent = MODEL_EVENT,
  innerValueName = INNER_VALUE
} = {}) {
  return {
    model: {
      prop: modelName,
      event: modelEvent
    },
    props: {
      [modelName]: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        [innerValueName]: this[modelName]
      }
    },
    watch: {
      [modelName]: {
        deep: true,
        handler (data) {
          if (isEqual(data, this[innerValueName])) return
          this[innerValueName] = data
        }
      },
      [innerValueName]: {
        deep: true,
        handler (data) {
          this.$emit(modelEvent, data)
        }
      }
    }
  }
}
