<template>
  <v-select
    outlined
    dense
    hide-details
    v-bind="attrs"
    v-on="$listeners"
  />
</template>

<script>
const FIELD_TEXT = 'id'
const FIELD_VALUE = 'value'

export default {
  methods: {
    confirm () {
      return confirm('Really?')
    }
  },
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        itemText: FIELD_TEXT,
        itemValue: FIELD_VALUE,
        items: [
          {
            [FIELD_TEXT]: 'RU',
            [FIELD_VALUE]: 'ru'
          },
          {
            [FIELD_TEXT]: 'EN',
            [FIELD_VALUE]: 'en'
          }
        ]
      }
    }
  }
}
</script>
